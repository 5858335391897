<template>
  <div>
    <a-card style="background-color: #fff">
      <div :class="advanced ? 'search' : null" v-if="!selectId">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="客户经理"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-select
                    allowClear
                    v-model="queryData.staffIdlist"
                    show-search
                    mode="multiple"
                    :filter-option="
                      (input, option) => {
                        return (
                          option.componentOptions.children[0].text
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                    "
                    style="width: 100%"
                    placeholder="请选择客户经理"
                    :default-value="null"
                    option-label-prop="label"
                  >
                    <a-select-option
                      :value="item.id"
                      :label="item.name"
                      v-for="(item, index) in users"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <!-- 多选 -->
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="客户"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-select
                    allowClear
                    v-model="queryData.customerId"
                    show-search
                    :filter-option="
                      (input, option) => {
                        return (
                          option.componentOptions.children[0].text
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                    "
                    style="width: 100%"
                    placeholder="请选择客户"
                    :default-value="null"
                    option-label-prop="label"
                  >
                    <a-select-option
                      :value="item.id"
                      :label="item.name"
                      v-for="(item, index) in customerData"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="被叫号码"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    v-model="queryData.calledNo"
                    placeholder="请输入被叫号码"
                  />
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="类型"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-select
                    allowClear
                    v-model="queryData.direction"
                    style="width: 100%"
                    placeholder="请选择类型"
                    option-label-prop="label"
                  >
                    <a-select-option value="I" label="呼入"
                      >呼入</a-select-option
                    >
                    <a-select-option value="O" label="呼出"
                      >呼出</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="地址"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <div style="display: flex; margin-top: 6px">
                    <a-select
                      allowClear
                      @change="provChange"
                      v-model="queryData.province"
                      :filter-option="
                        (input, option) => {
                          return (
                            option.componentOptions.children[0].text
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      "
                      show-search
                      placeholder="省"
                      option-label-prop="label"
                    >
                      <a-select-option
                        v-for="item in prov"
                        :key="item.name"
                        :value="item.id"
                        :label="item.name"
                        >{{ item.name }}
                      </a-select-option>
                    </a-select>
                    <a-select
                      @change="cityChange"
                      allowClear
                      placeholder="市"
                      v-model="queryData.district"
                      :filter-option="
                        (input, option) => {
                          return (
                            option.componentOptions.children[0].text
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      "
                      show-search
                      option-label-prop="label"
                    >
                      <a-select-option
                        v-for="item in city"
                        :key="item.id"
                        :value="item.name"
                        :label="item.name"
                        >{{ item.name }}
                      </a-select-option>
                    </a-select>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="通话发起时间"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-range-picker
                    :value="dateValue"
                    :ranges="{
                      今天: [moment(), moment()],
                      昨天: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      当月: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      上月: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    }"
                    @change="dateChange"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="处理状态"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-select
                    allowClear
                    v-model="queryData.status"
                    style="width: 100%"
                    placeholder="请选择处理状态"
                    option-label-prop="label"
                  >
                    <a-select-option value="dealing" label="已接听"
                      >已接听</a-select-option
                    >
                    <a-select-option value="notDeal" label="振铃未接听"
                      >振铃未接听</a-select-option
                    >
                    <a-select-option value="queueLeak" label="排队放弃"
                      >排队放弃</a-select-option
                    >
                    <a-select-option value="voicemail" label="已留言"
                      >已留言</a-select-option
                    >
                    <a-select-option value="leak" label="IVR放弃"
                      >IVR放弃</a-select-option
                    >
                    <a-select-option value="blackList" label="黑名单"
                      >黑名单</a-select-option
                    >
                    <a-select-option value="limit" label="并发限制"
                      >并发限制</a-select-option
                    >
                    <a-select-option value="failed" label="失败"
                      >失败</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 420px">
                <a-form-item
                  label="通话时长"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    style="width: 47%"
                    v-model="queryData.callTimeLengthBegin"
                    placeholder="请输入开始时间"
                  />
                  -
                  <a-input
                    style="width: 47%"
                    v-model="queryData.callTimeLengthEnd"
                    placeholder="请输入结束时间"
                  />
                  <!-- <a-time-picker style="width: 48%" placeholder="请选择开始时间" :value="callTimeLengthBegin" @change="scChange" /> -->
                  <!-- <a-time-picker style="width: 48%" placeholder="请选择结束时间" :value="callTimeLengthEnd" @change="scendChange" /> -->
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24">
                <a-button @click="reset" style="margin-left: 10px"
                  >重置</a-button
                >
                <a-button
                  type="primary"
                  style="margin-top: 4px; margin-left: 10px"
                  @click="search"
                  >查询
                </a-button>
                <!-- <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                          @click="asyncAudio">同步录音
                </a-button> -->
              </a-col>
            </a-row>
          </div>
        </a-form>
        <div style="margin-top: 0px; font-size: 15px; margin-left: 10px">
          显示第 {{ total > 0 ? 1 : 0 }} 至
          {{ queryData.size > total ? total : queryData.size }} 项结果，共
          {{ total }} 项
        </div>
      </div>

      <!--客户详情 -->
      <div v-if="selectId">
        <a-form layout="horizontal">
          <div style="display: flex; align-items: center">
            <div style="margin-top: 0px; font-size: 15px">
              显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共
              {{ total }} 项
            </div>
            <div style="display: flex; margin-left: auto">
              <div
                style="
                  display: flex;
                  width: 200px;
                  align-items: center;
                  margin-right: 10px;
                "
              >
                <span style="width: 50px">类型</span>
                <a-select
                  allowClear
                  v-model="queryData.direction"
                  placeholder="请选择类型"
                  option-label-prop="label"
                >
                  <a-select-option value="I" label="呼入">呼入</a-select-option>
                  <a-select-option value="O" label="呼出">呼出</a-select-option>
                </a-select>
              </div>
              <div style="display: flex; width: 200px; align-items: center">
                <span style="width: 50px">日期</span>
                <a-date-picker
                  :value="dateValue"
                  @change="dateChange"
                  :allowClear="true"
                />
              </div>
            </div>
            <a-button @click="reset" style="margin-left: 10px">重置</a-button>
            <a-button
              type="primary"
              style="margin-top: 4px; margin-left: 10px"
              @click="getData"
              >查询</a-button
            >
          </div>
        </a-form>
      </div>

      <div>
        <a-table
          :columns="columns"
          bordered
          :scroll="{ x: 1500 }"
          :dataSource="dataSource"
          :loading="tableLoading"
          :pagination="false"
        >
          <template slot="url" slot-scope="text, record">
            <span v-if="record.callTimeLength == 0">暂无录音</span>
            <audio v-else-if="text" :src="text" controls="controls"></audio>
            <audio
              v-else
              :src="record.fileServer + '/' + record.recordFileName"
              controls="controls"
            ></audio>
          </template>
          <template slot="staffList" slot-scope="record">
            <div
              v-for="item in record"
              :key="item.id"
              style="display: flex; align-items: center; cursor: pointer"
              @click="showModal(item.avatarPath)"
            >
              <img
                v-if="item.avatarPath"
                style="width: 40px; height: 40px; border-radius: 100%"
                :src="item.avatarPath"
                alt=""
              />
              <img
                v-else
                style="width: 40px; height: 40px; border-radius: 100%"
                src="https://file.youjiamall.cn/head-mr.png"
                alt=""
              />
              <span>{{ item.name }}</span>
            </div>
          </template>
        </a-table>
        <div class="page-box">
          <a-pagination
            :total="total"
            :current="queryData.page + 1"
            :page-size-options="pageSizeOptions"
            :pageSize="queryData.size"
            @change="handlePageChange"
            @showSizeChange="onShowSizeChange"
            show-size-changer
          />
        </div>
      </div>
    </a-card>
    <a-modal :visible="isImgShow" @cancel="handleCancel" :footer="null">
      <img :src="showimgurl" style="width: 100%" />
    </a-modal>
    <!-- <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
        <a-empty :description="false" />
        无权限查看
    </div> -->
  </div>
</template>

<script>
import { totalTime } from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
import { request } from "@/utils/request";
import { publicMixin } from "@/mixin/public";

const columns = [
  {
    title: "序号",
    customRender: (text, record, index) => index + 1,
    align: "center",
    width: 100,
  },

  { title: "客户", dataIndex: "customer", align: "center", width: 100 },
  {
    title: "呼出客户经理",
    dataIndex: "agentName",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "agentName" },
  },
  { title: "被叫号码", dataIndex: "calledNo", align: "center", width: 100 },
  { title: "处理结果", dataIndex: "statusName", align: "center", width: 100 },
  {
    title: "录音文件地址",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
    align: "center",
    width: 330,
  },
  {
    title: "通话时长",
    dataIndex: "callTimeLength",
    align: "center",
    width: 200,
  },
  {
    title: "通话发起时间",
    dataIndex: "offeringTime",
    align: "center",
    width: 200,
  },
  {
    title: "结束时间",
    dataIndex: "endTime",
    align: "center",
    width: 200,
  },
  { title: "省份", dataIndex: "province", align: "center", width: 100 },
  { title: "城市", dataIndex: "district", align: "center", width: 100 },
  { title: "类型", dataIndex: "directionName", align: "center", width: 100 },

  // {
  //     title: '操作',
  //     scopedSlots: { customRender: 'action' },
  //     align: 'center'
  // }
];
export default {
  name: "QueryList",
  mixin: [publicMixin],
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
    selectId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showimgurl: "",
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      current: 1,
      dateValue: "",
      total: 0,
      users: [],
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        callTimeLengthBegin: "",
        callTimeLengthEnd: "",
      },
      pageSizeOptions: ["10", "30", "40", "50", "100", "500", "1000"],
      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      currentPage: 1,
      city: [],
      prov: [],
      isImgShow: false,
    };
  },
  authorize: {
    deleteRecord: "delete",
  },
  watch: {
    selectId: {
      immediate: true, // 立即执行一次
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.getData();
    // 客户列表
    request(process.env.VUE_APP_API_BASE_URL + "sfa/customer/list", "get").then(
      (res) => {
        this.customerData = res.data.data;
      }
    );
    request(process.env.VUE_APP_API_BASE_URL + "api/adcode/list/0", "get").then(
      (res) => {
        if (res.data.code == 200) {
          this.prov = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      }
    );

    //  客户经理
    request(process.env.VUE_APP_API_BASE_URL + "sfa/users/list", "post").then(
      (res) => {
        this.users = res.data.data;
      }
    );
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    },
  },
  methods: {
    asyncAudio() {
      this.$message.loading();
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      let endTime = this.formatDate(new Date());
      let params = {
        startTime: year + "-" + month + "-" + day + " 00:00:00",
        endTime: endTime + ":00",
      };
      request(
        process.env.VUE_APP_API_BASE_URL + "sfa/intercomrecord/synchData",
        "post",
        params
      ).then((res) => {
        console.log(res, "res");
        this.$message.destroy();
        this.$message.success("同步成功！");
      });
    },
    showModal(text) {
      if (!text) {
        text = "https://file.youjiamall.cn/head-mr.png";
      }
      this.showimgurl = text;
      this.isImgShow = true;
    },
    handleCancel() {
      this.isImgShow = false;
    },
    search() {
      this.queryData.page = 0;
      this.getData();
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
      };
      this.dateValue = "";
      this.getData();
    },
    provChange(id) {
      this.city = [];
      delete this.queryData.cityCode; // 清空市的选择
      delete this.queryData.countyCode; // 清空区/县的选择
      if (!id) {
        return false;
      }
      let sel = this.prov.filter((item) => item.id === id);
      this.queryData.province = sel[0].name;
      request(
        process.env.VUE_APP_API_BASE_URL + "api/adcode/list/" + id,
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    cityChange(id) {
      if (!id) {
        return false;
      }
      let sel = this.city.filter((item) => item.id === id);
      this.queryData.district = sel[0].name;
      request(
        process.env.VUE_APP_API_BASE_URL + "api/adcode/list/" + id,
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    dateChange(date, dateString) {
      if (this.type == "user") {
        this.queryData.beginTime = dateString;
        this.queryData.endTime = dateString;
      } else {
        this.queryData.beginTime = dateString[0].toString();
        this.queryData.endTime = dateString[1].toString();
      }
      this.dateValue = date;
    },
    // 通话时长
    scChange(date, dateString) {
      this.queryData.callTimeLengthBegin = dateString;
      this.callTimeLengthBegin = date;
    },
    scendChange(date, dateString) {
      this.queryData.callTimeLengthEnd = dateString;
      this.callTimeLengthEnd = date;
    },

    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.tableLoading = true;
      let hisUrl;
      if (this.type == "user") {
        hisUrl = "sfa/intercomrecord/listPageByCustomerId";
        this.queryData.customerIdList = [this.selectId];
      } else {
        this.queryData.page = 0;
        hisUrl = "sfa/intercomrecord/listPage";
      }
      this.dataSource = [];
      request(
        process.env.VUE_APP_API_BASE_URL + hisUrl,
        "post",
        this.queryData
      ).then(async (res) => {
        this.tableLoading = false;
        if (res.data.code == 200) {
          for (let item of res.data.data) {
            if (!item.url) {
              item.url = "";
            }
            item.callTimeLength = totalTime(item.callTimeLength);
          }
          this.dataSource = res.data.data;
          this.queryData.page = res.data.page;
          this.queryData.size = res.data.size;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.tableLoading = true;
      let hisUrl;
      if (this.type == "user") {
        hisUrl = "sfa/intercomrecord/listPageByCustomerId";
        this.queryData.customerIdList = [this.selectId];
      } else {
        this.queryData.size = 10;
        hisUrl = "sfa/intercomrecord/listPage";
      }
      this.dataSource = [];
      request(
        process.env.VUE_APP_API_BASE_URL + hisUrl,
        "post",
        this.queryData
      ).then(async (res) => {
        this.tableLoading = false;
        if (res.data.code == 200) {
          for (let item of res.data.data) {
            if (!item.url) {
              item.url = "";
            }
            item.callTimeLength = totalTime(item.callTimeLength);
          }
          this.dataSource = res.data.data;
          this.queryData.page = res.data.page;
          this.queryData.size = res.data.size;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.message);
        }
      });
      // this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },

    // 获取列表
    getData() {
      this.tableLoading = true;
      let hisUrl;
      if (this.selectId || this.type == "user") {
        hisUrl = "sfa/intercomrecord/listPageByCustomerId";
        this.queryData.customerIdList = [this.selectId];
      } else {
        this.queryData.page = 0;
        this.queryData.size = 10;
        hisUrl = "sfa/intercomrecord/listPage";
      }
      this.dataSource = [];
      request(
        process.env.VUE_APP_API_BASE_URL + hisUrl,
        "post",
        this.queryData
      ).then(async (res) => {
        this.tableLoading = false;
        if (res.data.code == 200) {
          for (let item of res.data.data) {
            if (!item.url) {
              item.url = "";
            }
            item.callTimeLength = totalTime(item.callTimeLength);
          }
          this.dataSource = res.data.data;
          this.queryData.page = res.data.page;
          this.queryData.size = res.data.size;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    DownUrl(url) {
      return new Promise((resolve, reject) => {
        request(
          process.env.VUE_APP_API_BASE_URL + "sfa/intercomrecord/download",
          "post",
          { soundRecordingUrl: url }
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData();
    },
    exportTask() {
      this.$message.loading("处理中");
      let params = this.queryData;
      params.remarks = "";
      request(
        process.env.VUE_APP_API_BASE_URL + "sfa/goods/cake/exportBrand",
        "post",
        params
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("导出成功！");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      // const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      return result;
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block;
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #eeecfe;
  border-radius: 4px;
  border: 1px dashed #5542f6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542f6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}

.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

.ant-card-body {
  padding: 12px;
}

.ant-table-wrapper {
  overflow-x: auto;
}
</style>
